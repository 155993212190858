<template>
    <div class="card card-custom">
   <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
            <h3 class="card-label">{{title}}
            <span class="d-block text-muted pt-2 font-size-sm">{{subTitle}}</span></h3>
        </div>
        <div class="card-toolbar">
           <slot name="toolbar"></slot>
        </div>
    </div>
    <div class="card-body">
        <slot name="content"></slot>
    </div>
</div>
</template>
<script>
  export default {
    props: ['title','subTitle'],
  }
</script>